// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function() {
  
  $('.selectpicker').selectpicker();

  $(".m_datepicker").datepicker({
    orientation: "bottom",
    todayHighlight: true,
    todayBtn: true,
    autoclose: true,
    format: "yyyy-mm-dd",
    templates: {
      leftArrow: '<i class="la la-angle-left"></i>',
      rightArrow: '<i class="la la-angle-right"></i>',
    },
  });

  $('.m_timepicker').timepicker({
    locale: "ko"
  });

  $('.m_datetimepicker').datetimepicker({
    format: 'YYYY-MM-DD hh:mm A',
    autoclose: true
  });

  $('.multi_datepicker').datepicker({
    calendarWeeks: false,
    todayHighlight: true,
    format: "yyyy-mm-dd",
    multidate: true
  });

  $('.export_datatable').DataTable({

    // DOM Layout settings
    dom: `<'row'<'col-sm-10'Br><'col-sm-2'f>><'row'<'col-sm-12't>>
    <'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

    lengthMenu: [5, 10, 25, 50],

    pageLength: 50,

    // Order settings
    order: [[0, 'desc']],
    buttons: [{
      extend: 'excel',
      exportOptions: {
        columns: "thead th:not(.noExport)"
      }
    }],
    language: {
      search: "검색 : ",
      emptyTable: "검색된 내역이 없습니다.",
      zeroRecords: "검색된 내역이 없습니다.",
      lengthMenu: "_MENU_",
      info: "현재 _START_ - _END_ / _TOTAL_건",
      infoEmpty: "데이터 없음",
      infoFiltered: "( _MAX_건의 데이터에서 필터링됨 )",
      loadingRecords: "로딩중...",
      processing: "잠시만 기다려 주세요...",
    },
  });

  $('.datatable').DataTable({
    dom: `<'row'<'col-sm-10'r><'col-sm-2'f>><'row'<'col-sm-12't>>
    <'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,
    lengthMenu: [5, 10, 25, 50],

    pageLength: 50,

    // Order settings
    order: [[0, 'desc']],
    language: {
      search: "검색 : ",
      emptyTable: "검색된 내역이 없습니다.",
      zeroRecords: "검색된 내역이 없습니다.",
      lengthMenu: "_MENU_",
      info: "현재 _START_ - _END_ / _TOTAL_건",
      infoEmpty: "데이터 없음",
      infoFiltered: "( _MAX_건의 데이터에서 필터링됨 )",
      loadingRecords: "로딩중...",
      processing: "잠시만 기다려 주세요...",
    },
  });

  if($(".price_field").val()){
    $(".price_field").val($(".price_field").val().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  }
  
  $(".price_field").bind('keyup', function(e){
    var rgx1 = /\D/g;
    var rgx2 = /(\d+)(\d{3})/;
    var num = this.value.replace(rgx1,"");
    
    while (rgx2.test(num)) num = num.replace(rgx2, '$1' + ',' + '$2');
    this.value = num;
  });

});
